import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { fetchApi } from '../../AjaxUtil';
import { AppContext } from '../../context/appContext';
import Configs from '../../Configs';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import Button from '../button/Button';
import '../../styles/offlineBatchSync.css';

const offlineBatchSync = function (props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { connection } = useContext(AppContext);
  const [offlineBatchData, setOfflineBatchData] = useState(
    OfflineBatchHandler.getOfflineBatchData(),
  );
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [date, setDate] = useState('');
  const [syncStatus, setSyncStatus] = useState('');
  const [pendingSync, setPendingSync] = useState(0);
  const [totalSync, setTotalSync] = useState(0);
  let pruneBarcodes = [];

  useEffect(() => {
    if (props.history.location.state !== undefined) {
      setDate(props.history.location.state.dataDate);
      calculateLatestCount(
        offlineBatchData,
        props.history.location.state.dataDate,
      );
    }
  }, []);

  useEffect(() => {
    if (pendingSync === 0) {
      setSyncStatus(t('offlineBatchSync_status_2'));
      setIsDisableBtn(true);
    } else {
      setSyncStatus(t('offlineBatchSync_status_1'));
      setIsDisableBtn(false);
    }
  }, [pendingSync]);

  const onSuccessCallback = (response) => {
    // const onSuccessCallback = (response, status) => {
    // handle success
    // console.log('offlineBatchSyncDetails succ', response, status);

    // Pick from fresh before update
    let offlineBatchDataRT = OfflineBatchHandler.getOfflineBatchData();

    const failedSync = response.failedSync;
    let offlineTempData = JSON.parse(offlineBatchDataRT);

    // update offlineBatchDataRT sync info
    if (failedSync.length !== 0) {
      // sync fail
      Object.keys(offlineTempData).forEach((obj, index) => {
        Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
          const dataDates = moment(offlineTempData[index].scanDate).format(
              'D MMM YYYY',
          );
          let isMatchRecordId = false;
      failedSync.forEach((data) => {
            if (data === offlineTempData[index].recordId) {
              isMatchRecordId = true;
            }
          });
          const isSyncFalse = offlineTempData[index].synced === false;
          if (!isMatchRecordId && isSyncFalse && dataDates === date && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
            offlineTempData[index].synced = true;
          }
        });
      });
    } else {
      // sync complete
      Object.keys(offlineTempData).forEach((obj, index) => {
        Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
          const isSyncFalse = offlineTempData[index].synced === false;
          if (isSyncFalse && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
        const dataDates = moment(offlineTempData[index].scanDate).format(
          'D MMM YYYY',
        );
        if (dataDates === date) offlineTempData[index].synced = true;
          }
        });
      });
    }
    OfflineBatchHandler.setOfflineBatchData(offlineTempData);
    offlineTempData = JSON.stringify(offlineTempData);
    calculateLatestCount(offlineTempData, date);
    /* setOfflineBatchData(offlineTempData); */
  };

  const onErrorCallback = () => {
    // const onErrorCallback = (responseErr, statusErr) => {
    // handle error
    // console.log('offlineBatchSyncDetails fail', responseErr, statusErr);
  };

  const onClickSyncAll = async () => {
    setIsDisableBtn(true);

    let offlineBatchDataRT = OfflineBatchHandler.getOfflineBatchData();

    // call api
    if (connection && offlineBatchDataRT) {
      let offlineBarcodes = JSON.parse(offlineBatchDataRT);

      let unSyncedBarcodes = [];
      for (const barcode of offlineBarcodes) {
        const dataDates = moment(barcode.scanDate).format(
            'D MMM YYYY',
        );
        if (dataDates === date && !barcode.synced) {
          if (barcode.recordId === undefined || barcode.barcode === undefined || barcode.admissionType === undefined
              || barcode.gateId === undefined || barcode.scanResult === undefined || barcode.scanDate === undefined
              || barcode.scanBy === undefined || barcode.scanCheck === undefined || barcode.scanMode === undefined
              || barcode.scannerProfile === undefined) {
            // Skip
          } else {
            unSyncedBarcodes.push(barcode);
          }
        }
      }

      if (unSyncedBarcodes.length > 0) {
        pruneBarcodes = [];
        pruneBarcodes.push(...unSyncedBarcodes);
        //console.log(">>>>unSyncedBarcodes: " + unSyncedBarcodes.length);
      const param = {
          offlineBatch: unSyncedBarcodes,
      };

      fetchApi(
        Configs.HEALTHCHECK.OFFLINE_SYNC_API_METHOD,
        Configs.HEALTHCHECK.OFFLINE_SYNC_API_PATH,
        onSuccessCallback,
        param,
        onErrorCallback,
      );
      }
    }
  };

  const backHandler = () => {
    history.push(Configs.OFFLINE_BATCH_SYNC.PATH);
  };

  function calculateLatestCount(tempData, tempDate) {
    let dataPendingSyncCount = 0;
    let dataTotalSyncedCount = 0;
    if (tempData) {
      const offlineTempData = JSON.parse(tempData);
      offlineTempData.forEach((data) => {
        const dataDates = moment(data.scanDate).format('D MMM YYYY');
        const dataSynced = data.synced;
        if (dataDates === tempDate) {
          if (dataSynced === true) dataTotalSyncedCount += 1;
          else dataPendingSyncCount += 1;
        }
      });
      setTotalSync(dataTotalSyncedCount);
      setPendingSync(dataPendingSyncCount);
    }
  }

  return (
    <div className='offlineSync-form'>
      <div className='offlineSync-container'>
        <div className='offlineSync-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('offlineBatchSync_backText')}
          </div>
          <div className='offlineSync-details'>
            {t('offlineBatchSync_headerText')}
          </div>
        </div>
        {date === '' ? (
          ''
        ) : (
          <>
            <div className='offlineSync-form-box'>
              <div className='offlineSync-date'>{date}</div>
              <div
                className={`offlineSync-title pending${
                  syncStatus === 'Completed' ? ' active' : ''
                }`}
              >
                {t('offlineBatchSync_pendingTitle')}
              </div>
              <div className='offlineSync-value pending'>{pendingSync}</div>
              <div className='offlineSync-title sync'>
                {t('offlineBatchSync_syncedTitle')}
              </div>
              <div className='offlineSync-value sync'>{totalSync}</div>
              <div className='offlineSync-title status'>
                {t('offlineBatchSync_statusTitle')}
              </div>
              <div className='offlineSync-value status'>{syncStatus}</div>
            </div>
            <Button
              cName='offlineSync-sync-btn'
              disable={isDisableBtn}
              funct={onClickSyncAll}
              text={t('offlineBatchSync_btnText')}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default offlineBatchSync;
