import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import ToastMessage from '../resultDisplay/ToastMessage';
import Button from '../button/Button';
import Auth from '../../controller/authHandler/AuthHandler';
import PairScannerAuth from '../../controller/authHandler/PairScannerAuthHandler';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import SyncHandler from '../../controller/syncHandler/SyncHandler';
import { fetchApi } from '../../AjaxUtil';
import Configs from '../../Configs';
import '../../styles/login.css';
import loginImg from '../../assets/images/login.png';
import successImg from '../../assets/images/success.png';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import jwt from 'jwt-encode';
import * as constants from '../../constants/constants';
import ScannerUserRoleHandler from '../../controller/authHandler/ScannerUseRoleHandler';

const Login = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    appMode,
    setToken,
    setScannerUser,
    setScannerInfo,
    scannerInfo,
    connection,
    setScannerUserRole
  } = useContext(AppContext);
  const { register, handleSubmit } = useForm();
  const [scannerName, setScannerName] = useState(
    PairScannerNameHandler.getScannerName(),
  );
  // const [scannerUserRole,setScannerUserRole] = useState('')
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginAlertMsg, setLoginAlertMsg] = useState();
  const [loginAlertStatus, setLoginAlertStatus] = useState();
  const [isShowMsg, setIsShowMsg] = useState();
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [showUnpairDialog, setShowUnpairDialog] = useState(false);
  const [btnDisabledStatus, setBtnDisabledStatus] = useState();
  const [isSyncComplete, setIsSyncComplete] = useState(true);
  const [offlineBatchData, setOfflineBatchData] = useState(
    OfflineBatchHandler.getOfflineBatchDataObj()
  );
  const scnHeight = window.innerHeight;
  const scnStyle = {
    paddingTop: scnHeight - 640, // total div height
  };
  let pruneBarcodes = [];

  useEffect(() => {
    if (offlineBatchData) {
      let tempSyncComplete = true;

        offlineBatchData.forEach((data) => {
        const dataSynced = data.synced;
        if (dataSynced === false) tempSyncComplete = false;
        // console.log('dataSynced', dataSynced, tempSyncComplete);
      });
      setIsSyncComplete(tempSyncComplete);
    }
    // console.log('isSyncComplete',isSyncComplete);
  }, []);

  const unpairScannerHandler = () => {
    showUnpairConfirmBox();
  };

  const showUnpairConfirmBox = () => {
    setShowUnpairDialog(true);
  };

  const cancelUnpairHandler = () => {
    setShowUnpairDialog(false);
  };

  const goToPairScanner = () => {
      const scannerProfileId = PairScannerAuth.getScannerInfo();
      Configs.PAIRSCANNER.UNPAIR_API_PATH = `scannerProfile/${scannerProfileId}/unpair/`;
      const paramProfile = {
        scannerProfileId: scannerInfo,
      };
      fetchApi(
        Configs.PAIRSCANNER.UNPAIR_API_METHOD,
        Configs.PAIRSCANNER.UNPAIR_API_PATH,
        onSuccessCallbackUnpair,
        paramProfile,
        onErrorCallbackUnpair,
      );
  }

  const confirmUnpairHandler = () => {
    if (isSyncComplete === true) {
      goToPairScanner();
    } else if (offlineBatchData) {
      let unSyncedBarcodes = [];
      for (const barcode of offlineBatchData) {
        if (!barcode.synced) {
            if (barcode.recordId === undefined || barcode.barcode === undefined || barcode.admissionType === undefined
                || barcode.gateId === undefined || barcode.scanResult === undefined || barcode.scanDate === undefined
                || barcode.scanBy === undefined || barcode.scanCheck === undefined || barcode.scanMode === undefined
                || barcode.scannerProfile === undefined) {
                // Skip
            } else {
                unSyncedBarcodes.push(barcode);
            }
        }
      }

      if (unSyncedBarcodes.length > 0) {
        pruneBarcodes = [];
        pruneBarcodes.push(...unSyncedBarcodes);

        const param = {
            offlineBatch: unSyncedBarcodes,
        };
        fetchApi(
          Configs.HEALTHCHECK.OFFLINE_SYNC_API_METHOD,
          Configs.HEALTHCHECK.OFFLINE_SYNC_API_PATH,
          onSuccessCallbackOffline,
          param,
          onErrorCallbackOffline,
        );
      } else {
        goToPairScanner()
      }
    } else {
      // x connection
      // console.log('no connection...');
      goToPairScanner()
    }
  };

  const onSuccessCallback = (response) => {
    // const onSuccessCallback = (response, status) => {
    // handle success
    // console.log('login succ', response, status);
    setLoginAlertStatus('success');
    // set scanner user
    ScannerUserHandler.setScannerUser(response.username);

    ScannerUserRoleHandler.setScannerUserRole(response.role)
    // set token
    const data = {
      scannerProfileId: PairScannerAuth.getScannerInfo(),
      scannerUserId: response._id,
      scannerUserRole: response.role,
      loggedInFrom: constants.GuestJWTUser,
      iat: constants.GuestJWTIat,
      exp: constants.GuestJWTexp,
    };
    const token = jwt(data, Auth.getSecret());
    Auth.setToken(token);
    setScannerUser(response.username);
    setScannerUserRole(response.role)
    setToken(token);
    // redirect
    history.push(Configs.SYNC.PATH);
  };

  const onSuccessCallbackUnpair = () => {
    // const onSuccessCallbackUnpair = (response, status) => {
    // handle success
    // console.log('Unpair succ', response, status);
    SyncHandler.removeSyncData();
    OfflineBatchHandler.removeOfflineBatchData();
    PairScannerAuth.removeScannerInfo();
    PairScannerAuth.removeScannerToken();
    PairScannerNameHandler.removeScannerName();
    Auth.removeSecret();
    Auth.removeToken();
    setScannerInfo(null);
    setScannerName(null);
    setScannerUserRole(null)
    setShowUnpairDialog(false);
  };

  const onSuccessCallbackOffline = (response) => {
    // const onSuccessCallbackOffline = (response, status) => {
    // handle success
    // console.log('login sync succ', response, status);
    if (isSyncComplete === false) {
      const failedSync = response.failedSync;
          let offlineTempData = offlineBatchData;

      // update offlineBatchData sync info
      if (failedSync.length !== 0) {
        // sync fail
              Object.keys(offlineTempData).forEach((obj, index) => {
                  Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
                      let isMatchRecordId = false;
        failedSync.forEach((data) => {
                          if (data === offlineTempData[index].recordId) {
                              isMatchRecordId = true;
                          }
                      });
            const isSyncFalse = offlineTempData[index].synced === false;
                      if (!isMatchRecordId && isSyncFalse && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
              offlineTempData[index].synced = true;
            }
          });
        });
        setIsSyncComplete(false);
      } else {
        // sync complete
        Object.keys(offlineTempData).forEach((obj, index) => {
                  Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
                      const isSyncFalse = offlineTempData[index].synced === false;
                      if (isSyncFalse && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
                          //console.log("...updated" + pruneBarcodes[preindex].recordId );
          offlineTempData[index].synced = true;
                      }
                  });
        });
        setIsSyncComplete(true);
      }
      OfflineBatchHandler.setOfflineBatchData(offlineTempData);
      goToPairScanner()
          /* offlineTempData = JSON.stringify(offlineTempData);
          setOfflineBatchData(offlineTempData); */
    }
    setShowUnpairDialog(false);
  };

  const onErrorCallback = (err, status, message) => {
    // const onErrorCallback = (response, status) => {
    // handle error
    // console.log('login err', response, status);
    if (message == 'Inactive scanner user.') {
      setLoginAlertMsg(t(Configs.LOGIN.INACTIVE_SCANNER));
    } else {
      setLoginAlertMsg(t(Configs.LOGIN.FAIL_MSG));
    }
    setLoginAlertStatus('fail');
    setIsShowMsg(true);
    setTimeout(() => {
      setIsShowMsg(false);
    }, Configs.LOGIN.FAIL_DISPLAY_DURATION);
  };

  const onErrorCallbackUnpair = () => {
    // const onErrorCallbackUnpair = (response, status) => {
    // handle error
    // console.log('err', response, status);
  };

  const onErrorCallbackOffline = () => {
    // const onErrorCallbackOffline = (response, status) => {
    // handle error
    // console.log('err', response, status);
    setShowUnpairDialog(false);
    goToPairScanner()
  };

  const onSubmit = async () => {
    setIsDisableBtn(true);
    // call api
    const param = {
      username,
      password,
    };
    await fetchApi(
      Configs.LOGIN.API_METHOD,
      Configs.LOGIN.API_PATH,
      onSuccessCallback,
      param,
      onErrorCallback,
    );
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (isShowMsg) {
      setIsDisableBtn(true);
    } else {
      setIsDisableBtn(false);
    }
  }, [isShowMsg]);

  useEffect(() => {
    if (isDisableBtn || !username || !password) {
      setBtnDisabledStatus(true);
    } else {
      setBtnDisabledStatus(false);
    }
  }, [isDisableBtn, username, password]);

  return (
    <div className='login-page'>
      <div className='login-container'>
        <form className='login-form'>
          <div className='login-msg-box'>
            <img src={successImg} alt='logo' className='scan-success-img' />
            <div className='scanner-details'>
              {' '}
              {t('login_pairedText_1')} {`${scannerName}`}{' '}
              {t('login_pairedText_2')}
            </div>
            <div
              className='unpair-btn'
              role='button'
              tabIndex={0}
              onClick={unpairScannerHandler}
              onKeyPress={unpairScannerHandler}
            >
              {t('login_unpairText')}
            </div>
          </div>
          <div className='login-form-box'>
            <img src={loginImg} alt='logo' className='login-app-logo' />
            <p className='login-form-title'>{t('login_title')}</p>
            <p className='login-form-desc'>{t('login_desc')}</p>
            <div className='form-username-group'>
              <input
                {...register('username', { required: true })}
                name='username'
                id='username'
                className='form-username'
                placeholder={t('login_username')}
                onChange={handleUsernameChange}
                value={username}
              />
            </div>
            <div className='form-password-group'>
              <input
                {...register('password', { required: true })}
                type='password'
                name='password'
                id='password'
                className='form-password'
                placeholder={t('login_password')}
                onChange={handlePasswordChange}
                value={password}
              />
            </div>
          </div>
          <div className='login-btm-container' style={scnStyle}>
            <Button
              cName='button'
              disable={btnDisabledStatus}
              funct={handleSubmit(onSubmit)}
              text={t('login_btnText')}
            />
            {isShowMsg && (
              <ToastMessage cName={loginAlertStatus} msg={loginAlertMsg} />
            )}
            <div className='pagination'>
              <div className='dot' />
              <div className='dot active' />
            </div>
            <div className='pagination-text'>{t('login_stepText')}</div>
          </div>
        </form>
      </div>
      {showUnpairDialog && (
        <div className='confirm-dialog unpair'>
          <p>{t('pairScanner_unpairConfirmText')}</p>
          <div className='btn-group unpair'>
            <Button
              cName='dialog-cancel unpair'
              disable={false}
              funct={cancelUnpairHandler}
              text={t('manualEntry_btnCancel')}
            />
            <Button
              cName='dialog-confirm unpair'
              disable={false}
              funct={confirmUnpairHandler}
              text={t('manualEntry_btnConfirm')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
