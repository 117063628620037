import React, { useEffect, useRef, useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/offlineBatchSync.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SyncHandler from '../../controller/syncHandler/SyncHandler';
import Configs from '../../Configs';
import GateDataHandler from '../../controller/eventHandler/GateHandler';
import { fetchApi } from '../../AjaxUtil';

const ScanCountReport = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [gateInfo] = useState(GateDataHandler.getGateInfo());
  

  useEffect(() => {
    fetchGateStatisicApi();
  }, []);

    async function fetchGateStatisicApi() {
    const param = {
      gateIds: gateInfo,
      duration: Configs.SCAN_COUNT_REPORT.DURATION
    };

    await fetchApi(
      Configs.SCAN_COUNT_REPORT.API_METHOD,
      Configs.SCAN_COUNT_REPORT.API_PATH,
      onSuccessCallback,
      param,
      onErrorCallback,
    );
  }
  const onSuccessCallback = (response) => {
    
    setDataList(response);
  };

  const onErrorCallback = () => {
    // const onErrorCallback = (response, status) => {
    // handle error
    // console.log('err', response, status);
  };

  const onclickHandler = (data) => {
    history.push(Configs.SCAN_COUNT_REPORT_DETAILS.PATH, {
      data
    });
  };

  // back to setting pg
  const backHandler = () => {
    history.push(Configs.SETTING.PATH);
  };

  return (
    <div className='offlineSync-form'>
      <div className='offlineSync-container'>
        <div className='offlineSync-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('report_backText')}
          </div>
          <div className='offlineSync-details'>
            {t('scanCountReport_headerText')}
          </div>
        </div>
        <div className='scan-count-list'>
          {dataList.map((data) => (
            <React.Fragment key={data.key}>
              <div
                key={data._id}
                className='scan-count-list-item'
                role='button'
                tabIndex='0'
                onClick={() => onclickHandler(data)}
                onKeyPress={() => onclickHandler(data)}
              >
                {data.key}
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScanCountReport;
