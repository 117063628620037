import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import ToastMessage from '../resultDisplay/ToastMessage';
import Button from '../button/Button';
import PairScannerAuth from '../../controller/authHandler/PairScannerAuthHandler';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import Auth from '../../controller/authHandler/AuthHandler';
import { fetchApi } from '../../AjaxUtil';
import Configs from '../../Configs';
import logoImg from '../../assets/images/logo.png';
import * as constants from '../../constants/constants';

const PairScanner = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const { setScannerInfo } = useContext(AppContext);
  const { register } = useForm();
  const [scannerId, setScannerId] = useState('');
  const [pin, setPin] = useState('');
  const [pairAlertMsg, setPairAlertMsg] = useState();
  const [pairAlertStatus, setPairAlertStatus] = useState();
  const [isShowMsg, setIsShowMsg] = useState();
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [btnDisabledStatus, setBtnDisabledStatus] = useState();
  const scnHeight = window.innerHeight;
  const scnStyle = {
    paddingTop: scnHeight - 628,
  };

  const handleScannerIdChange = (event) => {
    setScannerId(event.target.value);
  };

  const handlePinChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setPin(event.target.value);
    }
  };

  const onSuccessCallback = (response) => {
    // const onSuccessCallback = (response, status) => {
    // handle success
    // console.log('pair succ', response, status);
    setScannerInfo(scannerId);
    Auth.setSecret(response.secret);
    Auth.setToken(constants.GuestJWT(Auth.getSecret()));
    PairScannerAuth.setScannerInfo(response._id);
    PairScannerAuth.setScannerToken(response.token);
    PairScannerNameHandler.setScannerName(response.scannerName);
    setPairAlertStatus('success');
    setPairAlertMsg(t(Configs.PAIRSCANNER.SUCCESS_MSG));
    setIsShowMsg(true);
    setTimeout(
      () => history.push(Configs.LOGIN.PATH),
      Configs.PAIRSCANNER.SUCCESS_DISPLAY_DURATION,
    );
  };

  const onErrorCallback = () => {
    // const onErrorCallback = (response, status) => {
    // handle error
    // console.log('pair err', response, status);
    setPairAlertStatus('fail');
    setPairAlertMsg(t(Configs.PAIRSCANNER.FAIL_MSG));
    setIsShowMsg(true);
    setTimeout(() => {
      setIsShowMsg(false);
    }, Configs.PAIRSCANNER.FAIL_DISPLAY_DURATION);
  };

  const onClickContinue = async () => {
    setIsDisableBtn(true);
    // call api
    const param = {
      scannerName: scannerId,
      pin,
    };
    await fetchApi(
      Configs.PAIRSCANNER.API_METHOD,
      Configs.PAIRSCANNER.API_PATH,
      onSuccessCallback,
      param,
      onErrorCallback,
    );
  };

  useEffect(() => {
    if (isShowMsg) {
      setIsDisableBtn(true);
    } else {
      setIsDisableBtn(false);
    }
  }, [isShowMsg]);

  useEffect(() => {
    if (isDisableBtn || !scannerId || !pin) {
      setBtnDisabledStatus(true);
    } else {
      setBtnDisabledStatus(false);
    }
  }, [isDisableBtn, scannerId, pin]);

  useEffect(() => {
    if (PairScannerAuth.getScannerInfo() && Auth.getToken()) {
      history.push(Configs.SYNC.PATH);
    }
  }, []);

  return (
    <form className='pairScanner-form'>
      <div className='pairScanner-container'>
        <div className='pairScanner-form-box'>
          <img src={logoImg} alt='logo' className='pairScanner-app-logo' />
          <p className='pairScanner-form-title'>{t('pairScanner_title')}</p>
          <p className='pairScanner-form-desc'>{t('pairScanner_desc')}</p>
          <div className='form-scannerId-group'>
            <input
              {...register('scannerId', { required: true })}
              name='scannerId'
              id='scannerId'
              className='form-scannerId'
              placeholder={t('pairScanner_scannerID')}
              onChange={handleScannerIdChange}
              value={scannerId}
            />
          </div>
          <div className='form-password-group'>
            <input
              {...register('PIN', { required: true })}
              type='password'
              name='PIN'
              id='PIN'
              className='form-pin'
              placeholder={t('pairScanner_PIN')}
              onChange={handlePinChange}
              value={pin}
            />
          </div>
        </div>
        <div className='pairScanner-btm-container' style={scnStyle}>
          <Button
            cName='button'
            disable={btnDisabledStatus}
            funct={onClickContinue}
            text={t('pairScanner_btnText')}
          />
          {isShowMsg && (
            <ToastMessage cName={pairAlertStatus} msg={pairAlertMsg} />
          )}
          <div className='pagination'>
            <div className='dot active' />
            <div className='dot' />
          </div>
          <div className='pagination-text'>{t('pairScanner_stepText')}</div>
        </div>
      </div>
    </form>
  );
};

export default PairScanner;
