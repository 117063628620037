import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Popup = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    props.onClose();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className='popup-overlay'>
          <div className='popup'>
            <div className='popup-header'>
                <FontAwesomeIcon icon={props.icon} style={{color: "#FFD43B"}} />
                <span className='popup-title'>{props.title}</span>
                <FontAwesomeIcon icon={faTimes} onClick={handleClose}/>
            </div>
            
            <div className='popup-body'>{props.children}</div>
            <div className='popup-footer'>
              <Button
                cName='popup-submit-btn'
                funct={props.onSubmit}
                text={props.submitBtnLabel || ''}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Popup.propTypes = {
  icon: PropTypes.IconDefinition,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  submitBtnLabel: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default Popup;