import { fetchApi } from "../AjaxUtil";
import Configs from "../Configs";
import { getEventsFromGateId } from "../helpers";

export const isLatestConfigAvailable = async (onSuccessCallback) => {
    const params = getEventsFromGateId();
    if(params){
      await fetchApi(
        Configs.FETCH_LATEST_CONFIG.API_METHOD,
        Configs.FETCH_LATEST_CONFIG.API_PATH,
        onSuccessCallback,
        params
      );
    }
  };