import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Configs from '../../Configs';
import { fetchApi } from '../../AjaxUtil';
import '../../styles/report.css';
import { Skeleton } from 'antd'

function getScanInCount(scanIncount, t, isScanInCountPending) {
  return (
    <div className='report-scan-in-details'>
      <div className='report-details-title'>{t('report_scanInTitle')}</div>
      <div className='report-details-text'>
        {isScanInCountPending ? (
          <Skeleton.Input active={true} size={'small'} />
        ) : (
          scanIncount.toLocaleString('en-US')
        )}
      </div>
    </div>
  );
}

function getScanOutCount(scanOutCount,t,isScanOutCountPending) {
  return (
    <div className='report-scan-out-details'>
      <div className='report-details-title'>{t('report_scanOutTitle')}</div>
      <div className='report-details-text'>
        {isScanOutCountPending ? (
          <Skeleton.Input active={true} size={'small'} />
        ) : (
          scanOutCount.toLocaleString('en-US')
        )}
      </div>
    </div>
  );
}

const GateReportDetail = function ({ history }) {
  const historyy = useHistory();
  const { t } = useTranslation();
  const [gateName, setGateName] = useState('');
  const [duration] = useState('All'); // always = all
  const [patronScanInCount, setPatronScanInCount] = useState(0);
  const [patronScanOutCount, setPatronScanOutCount] = useState(0);

  const [staffScanInCount, setStaffScanInCount] = useState(0);
  const [staffScanOutCount, setStaffScanOutCount] = useState(0);
  
  const [isPatronScanInCountPending, setIsPatronScanInCountPending] = useState(true);
  const [isPatronScanOutCountPending, setIsPatronScanOutCountPending] = useState(true);
  const [isStaffScanInCountPending, setIsStaffScanInCountPending] = useState(true);
  const [isStaffScanOutCountPending, setIsStaffScanOutCountPending] = useState(true);

  useEffect(() => {
    if (history.location.state !== undefined) {
      setGateName(history.location.state.gateName);

      fetchGateStatisicApi(history.location.state.gateId);
    }
  }, []);

  const onSuccessCallback = (response) => {

    setPatronScanInCount(response.patronData.patronEntry);
    setPatronScanOutCount(response.patronData.patronExit);
    setStaffScanInCount(response.accredData.accredEntry);
    setStaffScanOutCount(response.accredData.accredExit);
    setIsPatronScanInCountPending(false);
    setIsPatronScanOutCountPending(false);
    setIsStaffScanInCountPending(false);
    setIsStaffScanOutCountPending(false);
  };

  const onErrorCallback = () => {
    // const onErrorCallback = (response, status) => {
    // handle error
    // console.log('err', response, status);
  };

  async function fetchGateStatisicApi(gate) {
    const param = {
      gateIds: gate,
      duration,
    };

    await fetchApi(
      Configs.GATE_REPORT_DETAILS.API_METHOD,
      Configs.GATE_REPORT_DETAILS.API_PATH,
      onSuccessCallback,
      param,
      onErrorCallback,
    );
  }

  const backHandler = () => {
    historyy.goBack();
  };

  return (
    <div className='report-form'>
      <div className='report-container'>
        <div className='report-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('report_backText')}
          </div>
          <div className='report-details'>
            {t('gateReport_headerText')}
          </div>
        </div>
        <p className='report-gate-name'>{gateName}</p>
        <div className='report-details-container'>
          <div className='report-section-title'>{t('report_patronTitle')}</div>
          <div className='report-details-sub-container'>
            {getScanInCount(patronScanInCount,t,isPatronScanInCountPending)}
            {getScanOutCount(patronScanOutCount,t,isPatronScanOutCountPending)}
          </div>
          <div className='report-section-title'>{t('report_staffTitle') }</div>
          <div className='report-details-sub-container'>
            {getScanInCount(staffScanInCount,t,isStaffScanInCountPending)}
            {getScanOutCount(staffScanOutCount,t,isStaffScanOutCountPending)}
          </div>
        </div>
      </div>
    </div>
  );
};

// props.history.location.state.from
GateReportDetail.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        gateId: PropTypes.string,
        gateName: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default GateReportDetail;
