import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import Configs from '../../Configs';
import * as constants from "../../constants/constants";
import {fetchApi} from "../../AjaxUtil";
import AppModeHandler from "../../controller/appModeHandler/AppModeHandler";
import '../../styles/viewAudit.css';

const ViewAudit = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const { barcode, setBarcode, auditBarcode, setAuditBarcode, auditTicketID, setAuditTicketID, fromPath} = useContext(AppContext);
  const [appMode] = useState(AppModeHandler.appMode());
  const [showOfflineMsg, setShowOfflineMsg] = useState(false);
  const [showNoAuditMsg, setShowNoAuditMsg] = useState(false);
  const [showAudit, setShowAudit] = useState(true);
  const [auditInfo, setAuditInfo] = useState();

  useEffect(() => {
    if (barcode || auditBarcode || auditTicketID) {
      // start of API - for online only
      if (appMode === constants.AppModeOnline) {
        const param = {
        };
        let apiPath = '';
        if (fromPath && fromPath === Configs.BARCODE_AUDIT_MANUALENTRY.PATH && auditBarcode) {
          apiPath = Configs.VIEW_AUDIT.API_PATH_BY_BARCODE + encodeURIComponent(auditBarcode);
        } else if (fromPath && fromPath === Configs.BARCODE_AUDIT_MANUALENTRY.PATH && auditTicketID) {
          apiPath = Configs.VIEW_AUDIT.API_PATH_BY_TICKETID + auditTicketID;
        } else {
          if (barcode.ticketId) {
            apiPath = Configs.VIEW_AUDIT.API_PATH_BY_TICKETID + barcode.ticketId;
          } else {
          apiPath = Configs.VIEW_AUDIT.API_PATH_BY_BARCODE + encodeURIComponent(barcode.barcode);
          }
        }
        //const apiPath = Configs.VIEW_AUDIT.API_PATH_BY_BARCODE + "Ufrv6bqZwEz5y6cQjcnGKp1AIr8ByN35d3/qMMvMLSk=";
        //console.log('..entered' + apiPath);
        fetchApi(
            Configs.VIEW_AUDIT.API_METHOD,
            apiPath,
            onSuccessCallback,
            param,
            onErrorCallback,
        );
      }
    }

    // Disable when no offline
    if (appMode === constants.AppModeOffline || appMode === constants.AppModeEmergency) {
      setShowOfflineMsg(true);
    }
  }, []);

  const onSuccessCallback = (response) => {
    // handle success
    //console.log('viewAudit succ', response);
    if (response.length > 0) {
      setAuditInfo(response);
    } else {
      setShowNoAuditMsg(true);
    }
    setShowAudit(true);
    setBarcode(undefined);
    setAuditBarcode(undefined);
    setAuditTicketID(undefined);
  };

  const onErrorCallback = () => {
    // handle error
    // console.log('viewAudit err', response, status);
    setBarcode(undefined);
    setAuditBarcode(undefined);
    setAuditTicketID(undefined);
  };

  // go to scanner
  const backHandler = () => {
    setBarcode(undefined);
    setAuditBarcode(undefined);
    setAuditTicketID(undefined);
    
    if (fromPath) {
      history.push(fromPath);
    } else {
      history.push(Configs.BARCODE_AUDIT.PATH);
    }
  };

  return (
    <div className='viewaudit-form'>
      <div className='viewaudit-container'>
        <div className='viewaudit-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('viewAudit_backText')}
          </div>
          <div className='viewaudit-details'>{t('viewAudit_headerText')}</div>
        </div>
        <div className='viewaudit-form-box'>
          {showAudit && (
              <div className='audit-details'>
                <div className='audit-details-title'>
                  <p>{t('viewAudit_detailsText')}</p>
                </div>
              <div className='audit-details-display'>
                {(auditInfo ?? []).map((audit, index) => (
                  <div className='audit-info col-12 row' key={index}>
                    <div className='col-5'>{t('viewAudit_scanDate')}</div>
                    <div className='col-7'>: {audit?.scanDate ? moment(audit?.scanDate).format('D MMM YYYY h:mm:ssa') : 'N.A.'}</div>
                    <div className='col-5'>{t('viewAudit_admissionType')}</div>
                    <div className='col-7'>: {audit?.admissionType}</div>
                    <div className='col-5'>{t('viewAudit_ticketId')}</div>
                    <div className='col-7'>: {audit?.barcodeinfo && audit.barcodeinfo[0]?.ticketId ? audit.barcodeinfo[0]?.ticketId : 'N.A.'}</div>
                    <div className='col-5'>{t('viewAudit_status')}</div>
                    <div className={`col-7 status-color ${audit?.scanResult === 1 ? '' : 'red'}`}>: {audit?.scanResult === 1 ? 'ALLOWED' : 'DENIED'}</div>
                    <div className='col-5'>{t('viewAudit_scannedBy')}</div>
                    <div className='col-7'>: {audit?.scanBy}</div>
                    <div className='col-5'>{t('viewAudit_scanner')}</div>
                    <div className='col-7'>: {audit?.scannerProfile}</div>
                    <div className='col-5'>{t('viewAudit_scanMode')}</div>
                    <div className='col-7'>: {audit?.scanMode}</div>
                    <div className='col-5'>{t('viewAudit_scanReason')}</div>
                    <div className='col-7'>: {audit?.scanMessage ? audit.scanMessage : 'N.A.'}</div>
                    <div className='col-5'>{t('viewAudit_overrideReason')}</div>
                    <div className='col-7'>: {audit?.overrideReason ? audit.overrideReason : 'N.A.'}</div>
                    <div className='col-5'>{t('viewAudit_scanCheck')}</div>
                    <div className='col-7'>: {audit?.scanCheck === 1 ? 'Yes' : 'No'}</div>
                    <div className='col-5'>{t('viewAudit_validatedBy')}</div>
                    <div className='col-7'>: {audit?.ticketId ? t('viewAudit_validatedByTicket') : (audit?.transactionId ? t('viewAudit_validatedByTransaction') : t('viewAudit_validatedByBarcode'))}</div>
                    <div className='col-5'>{t('viewAudit_validatedType')}</div>
                    <div className='col-7'>: {audit?.validateType ? audit.validateType : 'N.A.'}</div>
                  </div>
                ))}
                {showNoAuditMsg && (
                  <p>{t('barcodeAudit_noAudit')}</p>
                )}
              </div>
              </div>
           )}
            {showOfflineMsg && (
              <p>{t('viewAudit_offlineMsgText')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewAudit;
